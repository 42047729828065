import React from "react";
import $ from "jquery";;

const Navbar = (props) => {

  function toggleBar() {
    $("#wrapper").toggleClass("toggled");
  }

  function HeaderView() {
    /*
    const location = useLocation();
    let path = location.pathname;
    
    var paths = path.split("/");

    var converted = []

    for (var i = 0; i < paths.length; i++){
      if (paths[i] == ""){
        converted.push("Api");
      } else if (paths[i] == "stats"){
        converted.push("Stats Api");
      } else if (paths[i] == "external-interactions"){
        converted.push("External Interactions");
      } else if (paths[i] == "partner"){
        converted.push("Parner");
      } else if (paths[i] == "video-engager-api"){
        converted.push("Video Engager Api");
      }
      
    }

    let linkPath = [];

    for (var i = 0; i < converted.length; i++){
      if (i > 0){
        linkPath.push(<li><a className="nav-link"  > > </a></li>);
      }

      linkPath.push(<li><Link className="nav-link" to={`${converted[i]}`} > {converted[i]} </Link></li>);
      
    }

   

    
    return (<ul class="nav">
  {linkPath}
</ul>);
  */
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
      <button
        className="showallways navbar-toggler collapsed"
        type="button"
        onClick={() => toggleBar()}
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      {HeaderView()}
    </nav>
  );
};

export default Navbar;
