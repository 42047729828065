import SwaggerViewer from './SwaggerViewer.js';
import { Route } from 'react-router-dom';
import MarkdownViewer from './MarkdownViewer.js';
import APILIST from './APILIST';
const apiList = APILIST;
const routerList = [];
const getherData = function () {
  let counter = 0;
  // hack for missing environment
  const v = Date.now().toString();
  const baseServerPath = process.env.REACT_APP_SWAGGER_BASE_SERVER_URL;
  for (let i = 0; i < apiList.length; i++) {
    if (apiList[i].route && (apiList[i].route === '/')) {
      continue;
    } else if (apiList[i].url && (apiList[i].url.slice(-4) === 'yaml')) {
      routerList.push(
        <Route key={counter} path={apiList[i].route}>
          <SwaggerViewer definitionLink={baseServerPath + apiList[i].url + `?v=${v}`} />
        </Route>
      );
    } else if (apiList[i].url && (apiList[i].url.slice(-2) === 'md')) {
      routerList.push(
        <Route key={counter} path={apiList[i].route}>
          <MarkdownViewer mdData={apiList[i].url + `?v=${v}`} />
        </Route>
      );
    }
    counter += 1;
    if (apiList[i].sub) {
      const subs = apiList[i].sub;
      for (const sub of subs) {
        if (sub.route && (sub.route === '/')) {
          continue;
        } else if (sub.url && (sub.url.slice(-4) === 'yaml')) {
          routerList.push(
            <Route key={counter} path={sub.route}>
              <SwaggerViewer definitionLink={baseServerPath + sub.url + `?v=${v}`} />
            </Route>
          );
        } else if (sub.url && (sub.url.slice(-2) === 'md')) {
          routerList.push(
            <Route key={counter} path={sub.route}>
              <MarkdownViewer mdData={sub.url + `?v=${v}`} />
            </Route>
          );
        }
        counter += 1;
      }
    }
  }
};
getherData();

export default routerList;
