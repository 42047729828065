const APILIST = [
  {
    url: '/assets/home.md',
    header: 'Home',
    route: '/',
    show: false
  },
  {
    url: '/assets/authentication.md',
    header: 'Authentication API',
    route: '/authentication',
    show: true,
    sub: [
      {
        url: '/assets/video_engager_api.md',
        header: 'Authentication Example',
        route: '/video_engager_api',
        show: true
      },
      {
        url: '/static/docs/swagger_partner.yaml',
        header: 'Authentication Swagger Definition',
        route: '/video_engager_api_swagger',
        show: true
      }
    ]
  }, {
    header: 'Genesys Callback API',
    route: '/genesys_callback_api',
    url: '/static/docs/swagger.callbacks.yaml',
    show: true
  }, {
    header: 'Short URLs API',
    route: '/short_url_api',
    url: '/static/docs/swagger.shorturls.yaml',
    show: true
  },
  {
    url: '/assets/schedules.md',
    header: 'Schedules API',
    route: '/schedules',
    show: true,
    sub: [
      {
        url: '/assets/tenantApi.md',
        header: 'Tenant API',
        route: '/tenantApi',
        show: true
      },
      {
        url: '/assets/agentApi.md',
        header: 'Agent API',
        route: '/agentApi',
        show: true
      },
      {
        url: '/assets/schedule_example.md',
        header: 'Schedule Example',
        route: '/schedule_example',
        show: true
      },
      {
        url: '/static/docs/swagger.schedules.yaml',
        header: 'Schedules Swagger Definition',
        route: '/schedules_swagger',
        show: true
      }
    ]
  },
  {
    url: '/assets/scheduling.md',
    header: 'Scheduling',
    route: '/scheduling',
    show: false
  },
  {
    url: '/assets/SearchAndAnalyticsAPI.md',
    header: 'Search and Analytics API',
    route: '/SearchAndAnalyticsAPI',
    show: true,
    sub: [
      {
        url: '/assets/InteractionsAPI.md',
        header: 'Interactions API',
        route: '/InteractionsAPI',
        show: true
      },
      {
        url: '/assets/external_interactions.md',
        header: 'External Interactions Example',
        route: '/external_interactions',
        show: true
      },
      {
        url: '/assets/TimeseriesAPI.md',
        header: 'Timeseries API',
        route: '/TimeseriesAPI',
        show: true
      },
      {
        url: '/static/docs/swagger.stats.yaml',
        header: 'Search and Analytics API Swagger Definition',
        route: '/stats_swagger',
        show: true
      },
      {
        url: '/assets/stats.md',
        header: 'Search and Analytics Example',
        route: '/stats',
        show: true
      }
    ]
  },
  {
    url: '/assets/ShortUrlPinAPI.md',
    header: 'ShortUrl Pin API',
    route: '/ShortUrlPinAPI',
    show: true,
    sub: [
      {
        url: '/assets/pin_demo_documentation.md',
        header: 'Pin Example',
        route: '/pin_functionality',
        show: true
      },
      {
        url: '/static/swagger.pin.yaml',
        header: 'Pin Swagger Definition',
        route: '/pin_api_swagger',
        show: true
      }
    ]
  },
  {
    url: '/assets/WebHooksAPI.md',
    header: 'Web Hooks API',
    route: '/WebHooksAPI',
    show: true
  },
  {
    url: '/assets/partner.md',
    header: 'Partner Example',
    route: '/partner',
    show: false,
    sub: [
      {
        url: '/static/docs/swagger.yaml',
        header: 'Partner Swagger Definition',
        route: '/parner_swagger',
        show: true
      },
      {
        url: '/static/docs/swagger_partner.yaml',
        header: 'Videoengager API Swagger Definition',
        route: '/video_engager_api_swagger',
        show: true
      }
    ]
  },
  {
    url: '/assets/settings.md',
    header: 'Settings',
    route: '/settings',
    show: true,
    sub: [
      {
        url: '/static/docs/swagger.settings.yaml',
        header: 'Settings Swagger Definition',
        route: '/settings_api_swagger',
        show: true
      }
    ]
  }
];

export default APILIST;
