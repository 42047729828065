
import APILIST from './APILIST';
import APILink from './APILink.js';
const list = [];
const apiList = APILIST;
const getherLinks = function () {
  if (apiList !== null) {
    for (let i = 0; i < apiList.length; i++) {
      list.push(<APILink key={i} apiLinkData={apiList[i]} />);
    }
  }
};
getherLinks();
export default list;
