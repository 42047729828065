import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import style from "./markdown.css";

class MarkdownViewer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      terms: null,
      renderers: {
        code: ({ language, value }) => {
          return (
            <SyntaxHighlighter language="javascript" style={docco}>
              {value}
            </SyntaxHighlighter>
          );
        },
      },
    };
  }

  getherData(link) {
    fetch(link)
      .then((response) => response.text())
      .then((text) => {
        this.setState({ terms: text });
      });
  }

  componentDidMount() {
    this.getherData(this.props.mdData);
  }

  componentDidUpdate() {
     
  }

  render() {
    return (
      <div className="content innerFrame reactMarkDown">
        <ReactMarkdown
          className={style.reactMarkDown}
          plugins={[gfm]}
          renderers={this.state.renderers}
          source={this.state.terms}
        />
      </div>
    );
  }
}

export default MarkdownViewer;
