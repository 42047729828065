import React, { Component } from 'react';
import APILINKS from './apiLinks';
import { Link } from 'react-router-dom';

const base = '/';
class Sidebar extends Component {
  constructor (props) {
    super(props);
    this.state = {
      apiLinks: APILINKS
    };
  }

  render () {
    return (
      <nav className='bg-light border-right' id='sidebar-wrapper'>

        <Link className='navbar-brand' to={`${base}`}>
          <img
            src='/images/videoengager-logo2.png'
            width=''
            height='40'
            alt='VideoEngager'
          />
        </Link>

        <div className='sidebar-heading'>
          <Link to={`${base}`} className='list-group-item-action link-header'>
            API's
          </Link>
        </div>

        <div className='list-group list-group-flush'>{this.state.apiLinks}</div>

      </nav>
    );
  }
}

export default Sidebar;
