import React, { Component } from 'react';
import SwaggerUI from 'swagger-ui';
import '../node_modules/swagger-ui/dist/swagger-ui.css';

class SwaggerViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
        definitionLink: ""
      }
    }

  componentDidMount() {
    this.setState({
      definitionLink: this.props.definitionLink
    })
  }

  componentDidUpdate() {
    SwaggerUI({
      domNode: document.getElementById("api-data"),
      url: this.state.definitionLink
    })
  }

  render() {
    return (<div id="api-data" />);
  }
}

export default SwaggerViewer;