import React from 'react';
import { Link } from 'react-router-dom';

const APILink = (props) => {
  const header = props.apiLinkData.header;
  const route = props.apiLinkData.route;
  const show = props.apiLinkData.show;
  const sub = props.apiLinkData.sub;

  let link = [];
  const subLinks = [];

  function toggleVisibility (element) {
    if (!element.baseURI) {
      return;
    }
    if (!element.href) {
      return;
    }
    if (element.classList.contains('caret-down')) {
      if (element.baseURI.substring(element.baseURI.lastIndexOf('/')) !== element.href.substring(element.href.lastIndexOf('/'))) {
        return;
      }
    }
    element.parentElement.querySelector('.nested').classList.toggle('active');
    element.classList.toggle('caret-down');
  }

  const onLinkClick = function (event) {
    const e = event.target;
    if (!e) {
      return;
    }
    toggleVisibility(e);
  };

  if (sub) {
    for (const subLink of sub) {
      subLinks.push(
        <li key={subLink.route}>
          <Link
            key={subLink.route}
            to={subLink.route}
            className='caret accordion swagger-definition list-group-item list-group-item-action bg-light api-link'
          >
            {subLink.header}
          </Link>
        </li>);
    }
  }

  if (show) {
    link = (
      <li key={route}>
        <Link
          key={route}
          to={route}
          className='caret accordion swagger-definition list-group-item list-group-item-action bg-light api-link'
          onClick={sub ? onLinkClick : undefined}
        >
          {header}
        </Link>
        <ul
          className='nested'
          id={route.substring(1)}
          key={route.substring(1)}
        >
          {subLinks}
        </ul>
      </li>
    );
  }

  const finalDom = [<div key={route}>{link}</div>];
  return finalDom;
};

export default APILink;
