import React, { Component } from 'react';
import './App.css';
import './simple-sidebar.css';

import Sidebar from './Sidebar.js';
import Navbar from './Navbar.js';
import NotFound from './NotFound.js';
import MarkdownViewer from './MarkdownViewer.js';
import RouterList from './routerList';
import '../node_modules/swagger-ui/dist/swagger-ui.css';
import { Switch, Route } from 'react-router-dom';

class App extends Component {
  constructor (props) {
    super(props);
    this.state = {
      routerList: RouterList
    };
  }

  componentDidMount () {}

  componentDidUpdate () {}

  render () {
    return (
      <div className='d-flex' id='wrapper'>
        <Sidebar />

        <div className='App' id='page-content-wrapper'>
          <Navbar />

          <Switch>
            <Route exact path='/'>
              <MarkdownViewer mdData='/assets/home.md' />
            </Route>

            {this.state.routerList}

            <Route component={NotFound} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;
